// eslint-disable-next-line no-extra-semi
(function (widgets) {
  var captchaTokenId = null;
  var captchaVersion = '';
  var captchaSiteKey = '';
  var Captcha = widgets.Widget.extend(
    {
      constructor: function CaptchaConstructor(object) {
        mainNav.nextButton.bind(
          'click keypress',
          $.proxy(next_captcha, window, object)
        );
        mainNav.backButton.disable();
        mainNav.nextButton.disable();
        Captcha.__super__.constructor.call(this, object);
        captchaTokenId = object.token_id;
        captchaVersion = object.version;
        captchaSiteKey = object.RECAPTCHA_SITE_KEY;
        object.locale = window.locale;
        object.RECAPTCHA_DOMAIN = process.env.RECAPTCHA_DOMAIN;
        object.RECAPTCHA_TIMEOUT = 5000;
        object.RECAPTCHA_INTERVAL = 50;
        object.RECAPTCHA_SCRIPT = captchaVersion.includes('enterprise')
          ? 'enterprise.js'
          : 'api.js';
        object.RECAPTCHA_SCRIPT_PARAMS =
          captchaVersion === 'enterprise-v3'
            ? 'render=' + object.RECAPTCHA_SITE_KEY
            : 'hl=' + object.locale;
        // We want to be able to use execute in the next_captcha function
        if (captchaVersion === 'v3') {
          object.RECAPTCHA_SCRIPT_PARAMS +=
            '&render=' + object.RECAPTCHA_SITE_KEY;
        }

        if (typeof grecaptcha === 'undefined') {
          grecaptcha = {};
        }
      },
      render: function render(object) {
        Captcha.__super__.render.call(this, object);

        /**
         * Checks for the presence of either reCAPTCHA `render` o `enterprise` method
         * (depending on the version invoked)
         * from the Google script every period (50 ms) for a max time (5 seconds).
         *
         * If the method exists, it enable the navigation buttons
         *
         * If no method exists, then it can be assumed the external script
         * never loaded, and we cannot render the captcha.
         *
         **/
        var retries = 0;
        var max_retries =
          this._object.RECAPTCHA_TIMEOUT / this._object.RECAPTCHA_INTERVAL;
        var interval = setInterval(function () {
          if (retries > max_retries) {
            mainNav.backButton.enable();
            mainNav.nextButton.enable();
            $('input[name=' + captchaTokenId + ']').val('SCRIPT_LOADING_ERROR');
            clearInterval(interval);
          }

          if (
            (grecaptcha.hasOwnProperty('render') &&
              !captchaVersion.includes('enterprise')) ||
            (grecaptcha.hasOwnProperty('enterprise') &&
              captchaVersion.includes('enterprise'))
          ) {
            mainNav.backButton.enable();
            mainNav.nextButton.enable();
            clearInterval(interval);
          }
          retries++;
        }, this._object.RECAPTCHA_INTERVAL);

        if (this._object.version.includes('v3')) {
          $('#captcha_v2_element').hide();
        }

        window.onVerifyCaptcha = function (token) {
          $('input[name=' + captchaTokenId + ']').val(token);
        };
      },
      destroy: function destroy() {
        var tags = document.querySelectorAll('script[src*="/recaptcha/"]');
        tags.forEach(function (tag) {
          $(tag).remove();
        });

        // Note: By removing this badge, we cannot support the
        // Back button reloading this widget again
        $('.grecaptcha-badge').parent().remove();

        window.onVerifyCaptcha = $.noop;
      },
    },
    {
      types: ['captcha'],
      views: ['captcha'],
    }
  );

  var next_captcha = function () {
    if (captchaVersion.includes('v3')) {
      if ($('input[name=' + captchaTokenId + ']').val() === '') {
        let $recaptcha = captchaVersion.includes('enterprise')
          ? grecaptcha.enterprise
          : grecaptcha;

        if (!$recaptcha || typeof $recaptcha.ready !== 'function') {
          $('input[name=' + captchaTokenId + ']').val('SCRIPT_LOADING_ERROR');
          click_next({ key: '', type: 'click' });
          return;
        }
        mainNav.backButton.disable();
        mainNav.nextButton.disable();

        try {
          $recaptcha.ready(function () {
            global.loading_buttons('next_button');
            // Programmatically invoke the challenge
            $recaptcha
              .execute(captchaSiteKey, { action: 'submit' })
              .then(function (token) {
                $('input[name=' + captchaTokenId + ']').val(token);
                click_next({ key: '', type: 'click' });
                return;
              })
              .catch(function (error) {
                $('input[name=' + captchaTokenId + ']').val(
                  'SCRIPT_LOADING_ERROR'
                );
                click_next({ key: '', type: 'click' });
              });
          });
        } catch (e) {
          $('input[name=' + captchaTokenId + ']').val('SCRIPT_LOADING_ERROR');
          click_next({ key: '', type: 'click' });
        }

        return;
      } else {
        click_next({ key: '', type: 'click' });
        return;
      }
    }

    if (
      captchaVersion.includes('v2') ||
      $('input[name=' + captchaTokenId + ']').val() === 'SCRIPT_LOADING_ERROR'
    ) {
      click_next({ key: '', type: 'click' });
    }
  };

  Captcha.register();
  widgets.Captcha = Captcha;
})((Gryphon.widgets = Gryphon.widgets || {}));
